@font-face {
  font-family: 'Century Gothic';
  src: url('../public/fonts/gothic.woff');
}


body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Century Gothic', 'Gothic A1', 'Roboto', sans-serif;
  font-weight: 400;
  color: #93329E;
}

ul {
  list-style-type: none;
  padding-left: 0px;
}

a {
  color: #93329E;
  text-decoration: none;
}

input:focus, textarea:focus {
  outline-color: #93329E;
}


#the-works-logo {
  width: 10vh;
}

#root {
  display: inherit;
  flex-direction: inherit;
  min-height: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}