.header-title {
    text-align: center;
    font-weight: 700;
    font-size: 5vh;
}

.about-us-container {
    background-color: #93329E;
    color: #FAF9F6;
}

.intro-line {
    font-size: 4vh;
    font-weight: 700;
}

.logo {
    background-color: #FAF9F6;
    padding: 1vw;

}

#the-works-logo {
    width: 15vh;
}

.member-title {
    font-weight: 700;
    font-size: 3vh;
}

.member-title+p {
    width: 80%;
}

.conclusion {
    text-align: center;
    width: 50%;
}

.signature {
    text-align: center;
}

.signature p:first-of-type {
    font-size: 2.5vh;
    font-weight: 700;
}



@media (max-width:2560px) {
    .header-title {
        font-size: 3.5vh;
    }

    .signature p:first-of-type {
        font-size: 2.7vh;
        font-weight: 700;
    }


    .member-title {
        font-size: 2.7vh;
    }



}

@media (max-width:1440px) {}

@media (max-width:1200px) {}

@media (max-width:1024px) {}

@media (max-width:992px) {
    .member-title+p {
        width: 100%;
    }

    .member-title {
        font-size: 2.2vh;
    }

    .signature p:first-of-type {
        font-size: 2.2vh;
        font-weight: 700;
    }
}

@media (max-width:820px) {}

@media (max-width:768px) {
    .member-title {
        font-size: 2vh;
    }
}

@media (max-width:576px) {
    .header-title {
        font-size: 4vh;
    }

    .conclusion {
        width: 100%;
    }

    .about-us-container {
        font-size: 1.5vh;
    }

    .member-title {
        font-size: 2.3vh;
    }

}

@media (max-width:375px) {
    .header-title {
        font-size: 3vh;
    }

    .member-title {
        font-size: 2vh;
    }

    .about-us-container {
        font-size: 1.7vh;
    }


}