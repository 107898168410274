.section-4-grid img {
    max-width: 100%;
    height: 70vh;
    display: block;
    object-fit: cover;
    box-shadow: 1px 1px 5px rgba(128, 128, 128);
}

.section-4-grid h1 {
    width: 100%;
    text-shadow: 1px 1px 5px rgba(121, 121, 121, 0.3);
}


.section-4-emphasis {
    font-size: 2.5vh;
    font-weight: 400;
    line-height: 1.2;
    color: #440A67;
    text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.3)
}

.section-4-conclusion {
    font-size: 3vh;
    font-weight: 700;
    text-align: right;
    color: #440A67;
    text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.3)
}


@media (max-width:1200px) {}

@media (max-width:1024px) {}

@media (max-width:992px) {
    .section-4-grid ul {
        font-size: 1.5vh;
    }

    .section-4-emphasis {
        font-size: 1.7vh;

    }

    .section-4-conclusion {
        font-size: 2vh;
    }
}

@media (max-width:820px) {}

@media (max-width:768px) {}

@media (max-width:576px) {


    .section-4-grid ul {
        font-size: 2vh;
    }

    .section-4-emphasis {
        font-size: 2.2vh;

    }

    .section-4-conclusion {
        font-size: 3vh;

    }
}

@media (max-width:375px) {


    .section-4-grid ul {
        font-size: 1.7vh;
    }

    .section-4-emphasis {
        font-size: 2vh;

    }


    .section-4-conclusion {
        font-size: 2.5vh;
    }
}