.section-1-container {
    position: relative;
    box-shadow: 1px 1px 5px rgba(128, 128, 128);
    padding: 0;
}

.section-1-container>img {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
}



.text-on-image {
    position: absolute;
    top: 3vh;
    left: 3vh;
    width: 70%;
    font-size: 3.7vh;
    font-weight: 700;
    padding: 20px;
    background-color: rgb(255, 255, 255, 0.7);
    color: #440A67;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1)
}


@media (max-width:1200px) {}

@media (max-width:1024px) {
    .text-on-image {
        top: 3vh;
        left: 3vh;
        font-size: 3.2vh;
    }
}

@media (max-width:992px) {
    .text-on-image {
        top: 3vh;
        left: 3vh;
        font-size: 3vh;
    }
}

@media (max-width:820px) {
    .text-on-image {
        top: 3vh;
        left: 3vh;
        font-size: 2.5vh;
    }
}

@media (max-width:768px) {
    .text-on-image {
        top: 3vh;
        left: 3vh;
        font-size: 2.2vh;
    }
}

@media (max-width:576px) {
    .text-on-image {
        top: 3vh;
        left: 3vh;
        width: 70%;
        font-size: 1.7vh;
    }
}

@media (max-width:375px) {
    .text-on-image {
        top: 3vh;
        left: 3vh;
        font-size: 1.3vh;
    }
}