.section-3-header>h1 {
    text-shadow: 1px 1px 5px rgba(121, 121, 121, 0.3);
    width: 50%;
}


.section-3-grid img {
    width: 50vh;
    height: 30vh;
    display: block;
    object-fit: cover;
    box-shadow: 1px 1px 5px rgba(128, 128, 128);
}