.section-2-container>h1 {
    text-align: right;
    width: 50%;
    align-self: end;
    text-shadow: 1px 1px 5px rgba(121, 121, 121, 0.3)
}



.section-2-container img {
    max-width: 100%;
    height: 70vh;
    display: block;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 1px 1px 5px rgba(128, 128, 128);
}



@media (max-width:2560px) {}

@media (max-width:1440px) {}

@media (max-width:1200px) {}

@media (max-width:1024px) {}

@media (max-width:992px) {}

@media (max-width:820px) {}

@media (max-width:768px) {}

@media (max-width:576px) {}

@media (max-width:375px) {}