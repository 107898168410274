.partner-logo-item {
    box-shadow: 1px 1px 5px #808080;
    width: 80%;
}



.thumbnail-images-slider .swiper-wrapper {
    width: 30%;
    box-shadow: 1px 1px 5px #808080;

}



.thumbnail-images-slider .swiper-images {
    object-fit: cover;
    width: 100%;
    box-shadow: 1px 1px 5px #808080;
}

.thumbnail-images-slider .swiper-image-large-wrapper {
    width: 80vh;
}

.swiper-image-large-wrapper img {
    object-fit: cover;
    width: 100%;
    box-shadow: 1px 1px 5px #808080;

}



:root {
    --swiper-theme-color: #808080;
}

@media (min-width:2560px) {}

@media (max-width:2560px) {}

@media (max-width:1440px) {}

@media (max-width:1200px) {}

@media (max-width:1024px) {
    .thumbnail-images-slider .swiper-image-large-wrapper {
        width: 70vh;
    }
}

@media (max-width:992px) {}

@media (max-width:820px) {
    .thumbnail-images-slider .swiper-image-large-wrapper {
        width: 60vh;
    }
}

@media (max-width:768px) {
    .partner-logo-item {
        width: 100%;
    }



}

@media (max-width:576px) {
    .thumbnail-images-slider .swiper-wrapper {
        width: 60%;

    }

    .thumbnail-images-slider .swiper-image-large-wrapper {
        width: 40vh;
    }
}

@media (max-width:375px) {}