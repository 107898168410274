button.navbar-toggler {
    border-color: rgba(255, 0, 0, 0);
    color: #93329E
}

.nav-link, .nav-link:focus, .customActive {
    color: #93329E;
    font-weight: 700;
}

.nav-link:focus, .customActive {
    border-bottom: 0.125rem solid #93329E;
}


.nav-link:hover {
    color: #FAF9F6;
    background-color: #93329E;
}



#back-to-top {
    position: fixed;
    font-size: 0.875rem;
    text-align: center;
    margin-right: 1%;
    margin-bottom: 1%;
    bottom: 0;
    right: 0;
    display: none;
}

#back-to-top:hover {
    color: #93329E;
}

@media (max-width: 576px) {
    #back-to-top {
        font-size: 0.625rem;
    }
}