#about-us h2 {
    font-weight: 700;
    color: #440A67;
}

#our-partners img {
    width: 11.25rem;
}

#our-partners h2 {
    font-weight: 700;
    color: #440A67;
}




@media (max-width:1200px) {
    #our-partners img {
        width: 8.75rem;
    }
}

@media (max-width:992px) {
    #our-partners img {
        width: 9.375rem;
    }
}

@media (max-width:576px) {
    #our-partners img {
        width: 5rem;
    }
}