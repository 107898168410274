#footer-container {
    background-color: #440A67;

    /* background-image: linear-gradient(0deg, #440A67 78%, rgba(67, 10, 103, 0.85) 80%, rgba(67, 10, 103, 0.75)82%, rgba(67, 10, 103, 0.6) 85%, rgba(67, 10, 103, 0.5)88%, rgba(67, 10, 103, 0.4)90%, rgba(67, 10, 103, 0.3) 92%, rgba(67, 10, 103, 0.2) 95%, rgba(67, 10, 103, 0.1) 98%, rgba(67, 10, 103, 0) 100%); */
    color: #FAF9F6;
}

#footer-container a {
    color: #FAF9F6;
}


#footer-container div>h2 {
    font-weight: 700;
    font-size: 4vh;
}

#footer-container p {
    font-size: 2.5vh;
}


#footer-container ul, #footer-container ul p {
    font-size: 1.5vh;
}



@media (max-width: 1200px) {

    #footer-container div>h2 {
        font-weight: 700;
        font-size: 2.5vh;
    }

    #footer-container p {
        font-size: 1.8vh;
    }


    #footer-container ul, #footer-container ul p {
        font-size: 1.5vh;
    }

}


@media (max-width: 992px) {


    #footer-container div>h2 {
        font-weight: 700;
        font-size: 2.5vh;
    }

    #footer-container p {
        font-size: 1.7vh;
    }

    #footer-container ul, #footer-container ul p {
        font-size: 1vh;
    }

}

@media (max-width: 576px) {


    #footer-container div>h2 {
        font-weight: 700;
        font-size: 4vh;
    }

    #footer-container p {
        font-size: 3vh;
    }

    #footer-container ul, #footer-container ul p {
        font-size: 1.7vh;
    }

}