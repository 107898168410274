#tag-line {
    text-align: right;
}

#landing-link, #landing-link:hover {
    text-decoration: none;
    color: #892574;
    font-size: 2.25rem;
    font-weight: 700;
}


#landing-link img {
    width: 20%;
}




@media (max-width: 768px) {
    #landing-link img {
        width: 40%;
    }

    #tag-line {
        font-size: 1.5rem;
    }
}

@media (max-width: 576px) {
    #tag-line {
        font-size: 1.25rem;
    }
}