.section-header {
    font-size: 4vh;
    font-weight: 700;
    color: #440A67;
    text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.3)
}

@media (max-width:1200px) {}

@media (max-width:1024px) {
    .section-header {
        top: 3vh;
        left: 3vh;
        font-size: 3.2vh;
    }
}

@media (max-width:992px) {
    .section-header {
        top: 3vh;
        left: 3vh;
        font-size: 3vh;
    }
}

@media (max-width:820px) {
    .section-header {
        top: 3vh;
        left: 3vh;
        font-size: 2.5vh;
    }
}

@media (max-width:768px) {
    .section-header {
        top: 3vh;
        left: 3vh;
        font-size: 2.2vh;
    }
}

@media (max-width:576px) {
    .section-header {
        top: 3vh;
        left: 3vh;
        width: 100%;
        font-size: 3vh;
    }
}

@media (max-width:375px) {}