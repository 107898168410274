.map-address {}



@media (max-width:1200px) {}

@media (max-width:1024px) {
    .map-address {

        font-size: 3.2vh;
    }
}

@media (max-width:992px) {
    .map-address {

        font-size: 3vh;
    }
}

@media (max-width:820px) {
    .map-address {

        font-size: 2.5vh;
    }
}

@media (max-width:768px) {
    .map-address {

        font-size: 2.2vh;
    }
}

@media (max-width:576px) {
    .map-address {

        font-size: 3vh;
    }
}

@media (max-width:375px) {}